import "./Justices.css";
import { justices } from "./justices_data";
export default function Justices() {
  return (
    <div className="container">
      <div className="left-column">
        <h1 className="case-title">the</h1>
        <h1 className="case-title" style={{ fontSize: "60px" }}>
          bench
        </h1>
      </div>
      <div className="right-column">
        {justices.map((justice) => {
          return (
            <div className="justices-container">
              <div className="justice-box">
                <img
                  src={justice.img_url}
                  alt="Justice D.Y. Chandrachud.jpg"
                  height="100"
                />
                <div className="justice-section">
                  <h1 className="justice-name">{justice.name}</h1>
                  <p className="justice-description"></p>
                  {justice.term}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
