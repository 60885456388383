import Case from "./Case";
import "./cases.css";
import { useEffect, useState } from "react";

export default function Cases(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getCasesData = () => {
      const url = process.env.REACT_APP_API_BASE_URL || "";
      fetch(`${url}/cases`)
        .then((res) => res.json())
        .then((result) => {
          if (result && result.length) {
            setData(
              result.filter((brief) =>
                brief.name_of_case
                  .toLowerCase()
                  .includes(props.query.toLowerCase())
              )
            );
            setLoading(false);
          }
        })
        .catch((error) => console.log("featch cases data error ===", error));
    };

    getCasesData();
  }, [props]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cases">
      {data.map((brief) => (
        <Case
          key={brief._id}
          id={brief._id}
          caseName={brief.name_of_case}
          caseDate={brief.date_decided}
          caseDesc={brief.issue_summary}
        />
      ))}
    </div>
  );
}
