export const justices = [
  {
    name: "Dhananjaya Y. Chandrachud",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/5/51/Justice_D.Y._Chandrachud.jpg",
    term: "13 May 2016 (8 years, 128 days)-- Retiring November 24, 2024",
  },
  {
    name: "Sanjiv Khanna",
    img_url:
      "//upload.wikimedia.org/wikipedia/commons/thumb/a/a7/Justice_Sanjiv_Khanna-2.jpg/72px-Justice_Sanjiv_Khanna-2.jpg",
    term: "18 January 2019 (5 years, 244 days)",
  },
  {
    name: "Bhushan Ramkrishna Gavai",
    img_url:
      "//upload.wikimedia.org/wikipedia/commons/thumb/4/4c/B_R_Gavai.jpg/66px-B_R_Gavai.jpg",
    term: "24 May 2019 (5 years, 117 days)",
  },
  {
    name: "Surya Kant",
    img_url:
      "//upload.wikimedia.org/wikipedia/commons/thumb/7/76/Justice_Surya_Kant.jpg/66px-Justice_Surya_Kant.jpg",
    term: "24 May 2019 (5 years, 117 days)",
  },
  {
    name: "Hrishikesh Roy",
    img_url:
      "//upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Hrishikesh_Roy.jpg/83px-Hrishikesh_Roy.jpg",
    term: "23 September 2019(4 years, 361 days)",
  },
  {
    name: "Abhay S. Oka",
    img_url:
      "//upload.wikimedia.org/wikipedia/commons/thumb/3/35/Justice_AS_Oka.jpg/84px-Justice_AS_Oka.jpg",
    term: "31 August 2021 (3 years, 18 days)",
  },
  {
    name: "Vikram Nath",
    img_url:
      "//upload.wikimedia.org/wikipedia/commons/thumb/8/80/Hon%27ble_Justice_Vikram_Nath.jpg/84px-Hon%27ble_Justice_Vikram_Nath.jpg",
    term: "31 August 2021 (3 years, 18 days)",
  },
  {
    name: "Jitendra Kumar Maheshwari",
    img_url:
      "//upload.wikimedia.org/wikipedia/commons/thumb/2/26/Justice_JK_Maheshwari.jpg/84px-Justice_JK_Maheshwari.jpg",
    term: "31 August 2021 (3 years, 18 days)",
  },
  {
    name: "B. V. Nagarathna",
    img_url:
      "//upload.wikimedia.org/wikipedia/commons/thumb/7/70/Justice_BV_Nagarathna.jpg/66px-Justice_BV_Nagarathna.jpg",
    term: "31 August 2021 (3 years, 18 days)",
  },
  {
    name: "C. T. Ravikumar",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Justice_CT_Ravikumar.jpg/167px-Justice_CT_Ravikumar.jpg",
    term: "31 August 2021 (3 years, 18 days)	",
  },
  {
    name: "M. M. Sundresh",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Justice_MM_Sundresh.jpg/167px-Justice_MM_Sundresh.jpg",
    term: "31 August 2021 (3 years, 18 days)",
  },
  {
    name: "Bela Trivedi",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Justice_BM_Trivedi.jpg/120px-Justice_BM_Trivedi.jpg",
    term: "31 August 2021 (3 years, 18 days)	",
  },
  {
    name: "P. S. Narasimha",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Justice_PS_Narasimha.jpg/156px-Justice_PS_Narasimha.jpg",
    term: "31 August 2021 (3 years, 18 days)	30 October 2027",
  },
  {
    name: "Sudhanshu Dhulia",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/4/45/Justice_Sudhanshu_Dhulia.jpg/162px-Justice_Sudhanshu_Dhulia.jpg",
    term: "9 May 2022 (2 years, 132 days)	",
  },
  {
    name: "J. B. Pardiwala",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Pardiwala.jpg/167px-Pardiwala.jpg",
    term: "9 May 2022 (2 years, 132 days)",
  },
  {
    name: "Dipankar Datta",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Justice_Dipankar_Datta.jpg/156px-Justice_Dipankar_Datta.jpg",
    term: "12 December 2022 (1 year, 281 days)",
  },
  {
    name: "Pankaj Mithal",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0a/Justice_Pankaj_Mithal.jpg/167px-Justice_Pankaj_Mithal.jpg",
    term: "6 February 2023 (1 year, 225 days)",
  },
  {
    name: "Sanjay Karol",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Justice_Sanjay_Karol.jpg/133px-Justice_Sanjay_Karol.jpg",
    term: "6 February 2023 (1 year, 225 days)",
  },
  {
    name: "P. V. Sanjay Kumar",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Justice_P._V._Sanjay_Kumar.jpg/133px-Justice_P._V._Sanjay_Kumar.jpg",
    term: "6 February 2023 (1 year, 225 days)	",
  },
  {
    name: "Ahsanuddin Amanullah",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Justice_Ahsanuddin_Amanullah.jpg/133px-Justice_Ahsanuddin_Amanullah.jpg",
    term: "6 February 2023 (1 year, 225 days)",
  },
  {
    name: "Manoj Misra",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Justice_Manoj_Misra.jpg/133px-Justice_Manoj_Misra.jpg",
    term: "6 February 2023 (1 year, 225 days)",
  },
  {
    name: "Rajesh Bindal",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Justice_Rajesh_Bindal.jpg/153px-Justice_Rajesh_Bindal.jpg",
    term: "13 February 2023 (1 year, 218 days)",
  },
  {
    name: "Aravind Kumar",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7a/Justice_Aravind_Kumar.jpg/168px-Justice_Aravind_Kumar.jpg",
    term: "13 February 2023 (1 year, 218 days)",
  },
  {
    name: "Prashant Kumar Mishra",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Justice_Prashant_Kumar_Mishra.jpg/167px-Justice_Prashant_Kumar_Mishra.jpg",
    term: "19 May 2023 (1 year, 122 days)",
  },
  {
    name: "K. V. Viswanathan",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Justice_K._V._Viswanathan.jpg/133px-Justice_K._V._Viswanathan.jpg",
    term: "19 May 2023 (1 year, 122 days)",
  },
  {
    name: "Ujjal Bhuyan",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Justice_Ujjal_Bhuyan.jpg/133px-Justice_Ujjal_Bhuyan.jpg",
    term: "14 July 2023 (1 year, 66 days)",
  },
  {
    name: "Sarasa Venkatanarayana Bhatti",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b1/Justice_S._V._Bhatti.jpg/133px-Justice_S._V._Bhatti.jpg",
    term: "14 July 2023 (1 year, 66 days)",
  },
  {
    name: "Satish Chandra Sharma",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Justice_Satish_Chandra_Sharma.jpg/143px-Justice_Satish_Chandra_Sharma.jpg",
    term: "9 November 2023 (314 days)",
  },
  {
    name: "Augustine George Masih",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Justice_Augustine_George_Masih.jpg/156px-Justice_Augustine_George_Masih.jpg",
    term: "9 November 2023 (314 days)	",
  },
  {
    name: "Sandeep Mehta",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Justice_Sandeep_Mehta.jpg/153px-Justice_Sandeep_Mehta.jpg",
    term: "9 November 2023 (314 days)",
  },
  {
    name: "Prasanna B. Varale",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Justice_P._B._Varale.jpg/133px-Justice_P._B._Varale.jpg",
    term: "25 January 2024 (237 days)	",
  },
  {
    name: "N. Kotiswar Singh",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/Justice_N._Kotiswar_Singh.jpg/156px-Justice_N._Kotiswar_Singh.jpg",
    term: "18 July 2024 (62 days)",
  },
  {
    name: "R. Mahadevan",
    img_url:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Justice_R._Mahadevan.jpg/167px-Justice_R._Mahadevan.jpg",
    term: "18 July 2024 (62 days)",
  },
];
