import { useNavigate } from "react-router-dom";
import "./case.css";
export default function Case(props) {
  const navigation = useNavigate();

  const clickHandleTitle = (id) => {
    navigation(`/cases/${id}`);
  };

  return (
    <div className="post" onClick={() => clickHandleTitle(props.id)}>
      <div className="postInfo">
        <span className="postTitle">{props.caseName}</span>
        <span className="postDate">{props.caseDate}</span>
      </div>
      <p className="postDesc">{props.caseDesc}</p>
    </div>
  );
}
