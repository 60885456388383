import { Link } from "react-router-dom";
import "./bottombar.css";

export default function BottomBar() {
  return (
    <div className="bottom">
      <Link to="/about" className="bottom-text-box">
        <p className="bottom-text-box">About Kanoon</p>
      </Link>
      <p className="bottom-text-box">
        made by&nbsp;
        <a
          className="bottom-text"
          href="https://www.linkedin.com/in/nat-sharma/"
          target="_blank"
          rel="noreferrer"
        >
          natasha sharma
        </a>
      </p>
      <p className="bottom-text-box">
        powered by&nbsp;
        <a
          className="bottom-text"
          href="https://platform.openai.com/docs/api-reference/introduction"
          target="_blank"
          rel="noreferrer"
        >
          openAI API
        </a>
      </p>
      <p className="bottom-text-box">
        cited from&nbsp;
        <a
          className="bottom-text"
          href="https://www.sci.gov.in/"
          target="_blank"
          rel="noreferrer"
        >
          https://www.sci.gov.in/
        </a>
      </p>
    </div>
  );
}
