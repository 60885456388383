import TopBar from "./components/topbar/TopBar";
import BottomBar from "./components/topbar/BottomBar";
import Home from "./pages/Home";
import About from "./pages/About";
import Cases from "./components/cases/Cases";
import CaseDetails from "./components/cases/CaseDetails";
import Justices from "./pages/Justices";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";

function App() {
  const [query, setQuery] = useState("");

  return (
    <Router>
      <TopBar setQuery={setQuery} query={query} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/justices" element={<Justices />} />
        <Route path="/cases" element={<Cases query={query} />} />
        <Route path="/cases/:id" element={<CaseDetails />} />
      </Routes>
      <BottomBar />
    </Router>
  );
}

export default App;
