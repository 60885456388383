import React, { useEffect, useState } from "react";
import "./CaseDetails.css";
import { useParams } from "react-router-dom";
export default function CaseDetails() {
  const { id } = useParams();
  const [caseDetail, setCaseDetail] = useState(null);

  useEffect(() => {
    if (!id) return;
    const url = process.env.REACT_APP_API_BASE_URL || "";
    fetch(`${url}/cases/${id}`)
      .then((res) => res.json())
      .then((result) => {
        setCaseDetail(result);
      })
      .catch((error) => console.log("fetch case detail error ===", error));
  }, [id]);

  return caseDetail ? (
    <div className="container">
      <div className="left-column">
        <h1 className="case-title">{caseDetail.name_of_case}</h1>
      </div>
      <div className="right-column">
        <div className="date-container">
          <div className="date-box">
            <h2 className="date-section">date granted</h2>
            <p className="p-section">{caseDetail.date_granted}</p>
          </div>
          <div className="date-box">
            <h2 className="date-section">date decided</h2>
            <p className="p-section">{caseDetail.date_decided}</p>
          </div>
        </div>
        <div className="details-container">
          <h2 className="section">Issue Summary</h2>
          <p className="p-section">{caseDetail.issue_summary}</p>
          <h2 className="section">Facts of the Case</h2>
          <p className="p-section">{caseDetail.facts_of_the_case}</p>
          <h2 className="section">Conclusion</h2>
          <p className="p-section">{caseDetail.conclusion}</p>
          <h2 className="section">The Bench</h2>
          <p className="p-section">{caseDetail.judges}</p>
        </div>
      </div>
    </div>
  ) : null;
}
