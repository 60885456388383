import { useState } from "react";
import "./topbar.css";
import { FaBalanceScale, FaSearch, FaGavel } from "react-icons/fa";
import { MdOutlineAccountBalance } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

function TopBar(props) {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const handleClickSearch = () => {
    navigate("/cases");
  };

  const handleSearchEnter = (e) => {
    if (e.keyCode === 13) {
      props.setQuery(search);
      navigate("/cases");
    }
  };

  return (
    <div className="top">
      <div className="topLeft">
        <Link to="/">
          <MdOutlineAccountBalance className="topIcon" />
        </Link>
      </div>
      <div className="topCenter">
        <ul className="topList">
          <li className="topListItem">
            <Link to="/cases" className="link">
              <FaBalanceScale className="topIcon" />
              <div className="icon-title">Cases</div>
            </Link>
          </li>
          <li className="topListItem">
            <Link to="/justices" className="link">
              <FaGavel className="topIcon" />
              <div className="icon-title">Justices</div>
            </Link>
          </li>
        </ul>
      </div>
      <div className="topRight">
        <input
          type="text"
          placeholder=""
          className="search"
          onChange={(e) => setSearch(e.target.value)}
          onKeyUp={(e) => handleSearchEnter(e)}
        />
        <FaSearch
          style={{ cursor: "pointer" }}
          className="topSearchIcon"
          onClick={() => handleClickSearch()}
        />
      </div>
    </div>
  );
}

export default TopBar;
