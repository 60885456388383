import "./home.css";

function Home() {
  return (
    <div className="header">
      <div className="headerTitles">
        <span className="headerTitleSmTop">
          using AI to simplify Indian Supreme Court cases
        </span>
        <span className="headerTitleLg">Kanoon Project</span>
        <span className="headerTitleSmBottom">
          inspired by
          <p className="bottomTitleSubFont">
            <a
              href="https://www.oyez.org/"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: "#444" }}
            >
              Oyez.org
            </a>
          </p>
        </span>
      </div>
    </div>
  );
}

export default Home;
