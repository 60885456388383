import "../components/cases/CaseDetails.css";

export default function About() {
  return (
    <div className="container">
      <div className="left-column">
        <h1 className="case-title">about the Kanoon Project</h1>
      </div>
      <div className="right-column">
        <div className="details-container">
          <p
            style={{
              fontFamily: "Montserrat, system-ui",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "35px",
            }}
          >
            hi, i'm{" "}
            <a
              href="https://www.linkedin.com/in/nat-sharma/"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "black",
                fontFamily: "Montserrat, system-ui",
                fontSize: "20px",
                fontWeight: "600",
                lineHeight: "35px",
                textDecoration: "none",
              }}
            >
              nat
            </a>
            !
            <br />i think indian law should be accessible and digestible.
            <br />
            inspired by its American counterpart{" "}
            <a
              href="https://www.oyez.org/"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "black",
                fontFamily: "Montserrat, system-ui",
                fontSize: "20px",
                fontWeight: "600",
                lineHeight: "35px",
                textDecoration: "none",
              }}
            >
              Oyez.org
            </a>
            , the Kanoon Project simplifies over 20,000 Indian Supreme Court
            cases using AI so you can read them in cute, bite-sized pieces.
            <br />
            of course, AI can make mistakes, so take it with a grain of salt.
          </p>
        </div>
      </div>
    </div>
  );
}
